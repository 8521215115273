import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import striptags from "striptags";
import handleViewport from "react-in-viewport";

const Faq = (props) => {
  const faqData = props.faqData;
  const { forwardedRef, inViewport } = props;

  useEffect(() => {
    console.clear();
  }, []);

  return (
    <section ref={forwardedRef} className="faq-section">
      {inViewport && (
        <div className="faq-inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="section-heading">
                  <span itemProp="heading" dangerouslySetInnerHTML={{
                    __html: faqData?.value?.title,
                  }}></span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="faq-accordion">
                  <Accordion defaultActiveKey={0} flush>
                    {faqData?.value?.list.map((item, itemIndex) => (
                      <Accordion.Item key={itemIndex} eventKey={itemIndex}>
                        <Accordion.Header>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: item?.value?.title,
                            }}
                          ></span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item?.value?.description,
                            }}
                          ></p>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

const FaqViewportBlock = handleViewport(Faq);

export default FaqViewportBlock;
